import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: () => import('../views/IntroView.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/LoginView.vue')    
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('../views/HomeView.vue')
  },
  {
    path: '/forget-password',
    name: 'forget-password',
    component: () => import('../views/ForgetPasswordView.vue')
  },
  {
    path: '/forget-password-end',
    name: 'forget-password-end',
    component: () => import('../views/ForgetPasswordEndView.vue')
  },
  {
    path: '/create-account',
    name: 'create-account',
    component: () => import('../views/CreateAccountView.vue')
  },
  {
    path: '/order',
    name: 'order',
    component: () => import('../views/OrderView.vue')
  },
  {
    path: '/shipping',
    name: 'shipping',
    component: () => import('../views/ShippingView.vue')
  },
  {
    path: '/seller-master',
    name: 'seller-master',
    component: () => import('../views/SellerMasterView.vue')
  },
  {
    path: '/seller-master-payment',
    name: 'seller-master-payment',
    component: () => import('../views/SellerMasterPaymentView.vue')
  },
  {
    path: '/seller-master-shipping',
    name: 'seller-master-shipping',
    component: () => import('../views/SellerMasterShippingView.vue')
  },
  {
    path: '/seller-master-packaging',
    name: 'seller-master-packaging',
    component: () => import('../views/SellerMasterPackagingView.vue')
  },
  {
    path: '/report-payment-list',
    name: 'report-payment-list',
    component: () => import('../views/ReportPaymentListView.vue')
  },
  {
    path: '/payment-card-fail',
    name: 'payment-card-fail',
    component: () => import('../views/PaymentCardFailView.vue')
  },
  {
    path: '/payment-card-success',
    name: 'payment-card-success',
    component: () => import('../views/PaymentCardSuccessView.vue')
  },
  {
    path: '/link-payments',
    name: 'link-payments',
    component: () => import('../views/LinkPaymentView.vue')
  },
  {
    path: '/glovis-shipping',
    name: 'glovis-shipping',
    component: () => import('../views/ShippingGlovisView.vue')
  },
  {
    path: '/tracking',
    name: 'tracking',
    component: () => import('../views/OpenTrackingView.vue')
  },
  {
    path: '/report-weight-list',
    name: 'report-weight-list',
    component: () => import('../views/ReportWeightListView.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
