import Vue from "vue";
import "./plugins/axios";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import VueCookies from "vue-cookies";
import Notifications from "vue-notification";
// eslint-disable-next-line no-unused-vars
import VueSession from "vue-session";
// eslint-disable-next-line no-unused-vars
import VueAlertify from "vue-alertify";
import VueSimpleAlert from "vue-simple-alert";
import Print from "vue-print-nb";
import VueCountryCode from "vue-country-code";
import common from '@/common'
import dateUtil from "@/common/DateUtil"

var sessionOptions = {
  persist: true,
};
// EventBus 생성
Vue.prototype.$EventBus = new Vue();

Vue.use(Print);
Vue.use(VueSession, sessionOptions);
Vue.use(Notifications);
Vue.use(VueCookies);
Vue.use(VueSimpleAlert);
Vue.use(VueAlertify, {
  // dialogs defaults
  autoReset: true,
  basic: false,
  closable: true,
  closableByDimmer: false,
  frameless: false,
  maintainFocus: true, // <== global default not per instance, applies to all dialogs
  maximizable: true,
  modal: true,
  movable: true,
  moveBounded: false,
  overflow: true,
  padding: true,
  pinnable: true,
  pinned: true,
  preventBodyShift: false, // <== global default not per instance, applies to all dialogs
  resizable: true,
  startMaximized: false,
  transition: "pulse",

  // notifier defaults
  notifier: {
    // auto-dismiss wait time (in seconds)
    delay: 5,
    // default position
    position: "top-right",
    // adds a close button to notifier messages
    closeButton: false,
  },

  // language resources
  glossary: {
    // dialogs default title
    title: "AlertifyJS",
    // ok button text
    ok: "OK",
    // cancel button text
    cancel: "Cancel",
  },

  // theme settings
  theme: {
    // class name attached to prompt dialog input textbox.
    input: "ajs-input",
    // class name attached to ok button
    ok: "ajs-ok",
    // class name attached to cancel button
    cancel: "ajs-cancel",
  },
});
Vue.use(VueCountryCode);
Vue.use(common);
Vue.use(dateUtil)

Vue.$cookies.config("30d"); // expire 1일 (global 설정)

Vue.config.productionTip = false;

new Vue({
  vuetify,
  router,
  render: (h) => h(App),
}).$mount("#app");

Vue.filter("currency", (value) => {
  if(value == null) { value = ""; }
  var result = "";
  switch(value.toUpperCase()) {
  case "KRW" : 
    result = "₩"
    break;
  case "USD" : 
    result = "$"
    break;
  case "CNY" : 
    result = "¥"
    break;
  case "RUB" : 
    result = "₽"
    break;
  default : 
    result = value
  }
  return result
});

Vue.filter("comma", (value) => {
  if(value == null) { value = ""; }
  value = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return value;
});

Vue.filter("totalQuantity", (value) => {
  if(value == null) { value = ""; }
  var result = 0;

  for(var count=0; count<value.length; count++){
    result += value[count].itemQuantity
  }
  return result;
});

Vue.filter("nullRemove", (value) => {
  var result = "";
  if(value == null || value.toLowerCase() == "null") { 
    result = "";
  } else {
    result = value;
  }
  return result;
});

Vue.filter("textOverflowBy13End", (value) => {
  var result = "";
  if(value.length > 13) { 
    result = value.substr(0, 13) + "...";
  } else {
    result = value;
  }
  return result;
});

Vue.filter("textOverflowBy70End", (value) => {
  var result = "";
  if(value.length > 70) { 
    result = value.substr(0, 70) + "...";
  } else {
    result = value;
  }
  return result;
});

Vue.filter("textOverflowBy18End", (value) => {
  var result = "";
  if(value.length > 18) { 
    result = value.substr(0, 18) + "...";
  } else {
    result = value;
  }
  return result;
});

Vue.filter("carrierName", (value) => {
    let result = "";

    if (value != null) {
        switch (value.toUpperCase()) {
            case "UPS":
                result = "UPS"
                break
            case "PX":
                result = "PX"
                break
            case "EMS":
                result = "EMS"
                break
            case "GEM":
                result = "GEM"
                break
            case "FEDEX_FICP":
                result = "FEDEX FICP"
                break
            case "FEDEX_IP":
                result = "FEDEX IP"
                break
            case "FEDEX_IP_C":
                result = "FEDEX IP C"
                break
            default:
                result = value.toUpperCase()
        }
    }
  
  return result;
});

Vue.filter("timestampToDate", (value) => {
  var result = "";
  var date = new Date(value);
  var year = date.getFullYear().toString(); //년도 뒤에 두자리
  var month = ("0" + (date.getMonth() + 1)).slice(-2); //월 2자리 (01, 02 ... 12)
  var day = ("0" + date.getDate()).slice(-2); //일 2자리 (01, 02 ... 31)
  var hour = ("0" + date.getHours()).slice(-2); //시 2자리 (00, 01 ... 23)
  var minute = ("0" + date.getMinutes()).slice(-2); //분 2자리 (00, 01 ... 59)
  var second = ("0" + date.getSeconds()).slice(-2); //초 2자리 (00, 01 ... 59)

  result = year + "-" + month + "-" + day + " " + hour + ":"  + minute + ":"  + second;

  return result;
});