function makeAPIUrl(path) {
  return process.env.VUE_APP_API_PROXYPASS + path;
}

function trackingStepBackgroundColor(trackingData) {
  var item = new Object();
  item.step1 = 'background-color:#9E9E9E; width:20%';
  item.step2 = 'background-color:#9E9E9E; width:20%';
  item.step3 = 'background-color:#9E9E9E; width:20%';
  item.step4 = 'background-color:#9E9E9E; width:20%';
  item.step5 = 'background-color:#9E9E9E; width:20%';
  
  for(var count = 0; count < trackingData.length; count++) {
    if(trackingData[count].event == "DELIVERED") {
      item.step1 = 'background-color:#ec6968; width:20%';
      item.step2 = 'background-color:#da5d8d; width:20%';
      item.step3 = 'background-color:#af5faa; width:20%';
      item.step4 = 'background-color:#6f68b9; width:20%';
      item.step5 = 'background-color:#036db2; width:20%';
    }
    if(trackingData[count].event == "FINAL_DEST_TRANSIT" || trackingData[count].event == "FINAL_DEST_ARRIVED" || trackingData[count].event == "IN_TRANSIT"
    || trackingData[count].event == "HUB_ARRIVED" || trackingData[count].event == "IN_DELIVERY") {
      item.step1 = 'background-color:#ec6968; width:20%';
      item.step2 = 'background-color:#da5d8d; width:20%';
      item.step3 = 'background-color:#af5faa; width:20%';
      item.step4 = 'background-color:#6f68b9; width:20%';
    }
    if(trackingData[count].event == "CLEARANCE_DELAY" || trackingData[count].event == "IN_CLEARANCE" 
    || trackingData[count].event == "CLEARED") {
      item.step1 = 'background-color:#ec6968; width:20%';
      item.step2 = 'background-color:#da5d8d; width:20%';
      item.step3 = 'background-color:#af5faa; width:20%';
    }
    if(trackingData[count].event == "SHIPPED") {
      item.step1 = 'background-color:#ec6968; width:20%';
      item.step2 = 'background-color:#da5d8d; width:20%';
    }
    if(trackingData[count].event == "LABEL_CREATED" || trackingData[count].event == "AWB_CREATED" || trackingData[count].event == "GEM_CENTER_ARRIVED"
    || trackingData[count].event == "READY_SHIP") {
      item.step1 = 'background-color:#ec6968; width:20%';
    }    
  }
  return item;
}
function getYYYMMDD(value) {
  if (value == "") return "";
  var js_date = new Date(value);
  var year = js_date.getFullYear();
  var month = js_date.getMonth() + 1;
  var day = js_date.getDate();

  if (month < 10) {
    month = "0" + month;
  }
  if (day < 10) {
    day = "0" + day;
  }

  return year + "-" + month + "-" + day;
}
function getYYYMMDDHHMMSS(value) {
  if (value == "") return "";
  var js_date = new Date(value);
  var year = js_date.getFullYear();
  var month = js_date.getMonth() + 1;
  var day = js_date.getDate();
  var hour = js_date.getHours();
  var minute = js_date.getMinutes();

  if (month < 10) { month = "0" + month; }
  if (day < 10) { day = "0" + day; }
  if (hour < 10) { hour = "0" + hour; }
  if (minute < 10) { minute = "0" + minute; }

  return year + "-" + month + "-" + day + " " + hour + ":" + minute;
}
function getCountry(value) {
  return "vti__flag " + value.toLowerCase();
}
function getRateImgUrl(value) {
  if(value == null) { value = ""; }
  var result = "";
  switch (value.toLowerCase()) {
      case "ups":
        result = require("@/images/ups.png")
        break
      case "px":
        result = require("@/images/px.png")
        break
      case "ems":
        result = require("@/images/ems.png")
        break
      case "gem":
        result = require("@/images/gem.png")
        break
      case "fedex_ficp":
        result = require("@/images/fedex.png")
        break
      case "fedex_ip":
        result = require("@/images/fedex.png")
        break
      case "fedex_ip_c":
        result = require("@/images/fedex.png")
        break
      case "gts":
        result = require("@/images/gem.png")
        break
      case "ups_gnd_fns":
        result = require("@/images/gem.png")
        break
      case "dhl_gnd_trident":
        result = require("@/images/gem.png")
        break
      case "ups_all_pass_fns":
        result = require("@/images/gem.png")
        break
      case "dhl_all_pass_trident":
        result = require("@/images/gem.png")
        break
      case "ics":
        result = require("@/images/gem.png")
        break
      case "mc":
        result = require("@/images/gem.png")
        break
      case "wise":
        result = require("@/images/gem.png")
        break
      default:
  }
  return result;
}
function getCarrierServiceImage(value) {
  if (value == null) { value = ""; }
  var result = ""
  switch (value.toLowerCase()) {
      case "ups":
        result = require("@/images/ups.png")
        break
      case "px":
        result = require("@/images/px.png")
        break
      case "ems":
        result = require("@/images/ems.png")
        break
      case "fedex ficp":
        result = require("@/images/fedex.png")
        break
      case "fedex ip":
        result = require("@/images/fedex.png")
        break
      case "fedex ip c":
        result = require("@/images/fedex.png")
        break
      case "gem":
        result = require("@/images/gem.png")
        break
      case "gem allpass":
        result = require("@/images/gem.png")
        break
      case "ics":
        result = require("@/images/gem.png")
        break
      case "mc":
        result = require("@/images/gem.png")
        break
      case "wise":
        result = require("@/images/gem.png")
        break
      default:
  }
  return result
}
function getCarrierName(value) {
  if(value == null) { value = ""; }
  var result = "";
  switch(value.toLowerCase()) {
      case "ups" : 
        result = "UPS"
        break;
      case "px" : 
        result = "PX"
        break;
      case "ems" : 
        result = "EMS"
        break;
      case "gem" : 
        result = "GEM"
        break;
      case "fedex_ficp" : 
        result = "FEDEX FICP"
        break;
      case "fedex_ip" : 
        result = "FEDEX IP"
        break;
      case "fedex_ip_c" : 
        result = "FEDEX IP C"
        break;
      case "gts":
        result = "GEM"
        break
      case "ups_gnd_fns":
        result = "GEM"
        break
      case "dhl_gnd_trident":
        result = "GEM"
        break
      case "ups_all_pass_fns":
        result = "GEM ALLPASS"
        break
      case "dhl_all_pass_trident":
        result = "GEM ALLPASS"
        break 
      case "ics":
        result = "GEM"
        break
      case "mc":
        result = "GEM"
        break
      case "wise":
        result = "GEM"
        break
      default : 
  }
  return result;
}
function getPlatformImgUrl(value) {
  if(value == null) { value = ""; }
  var result = "";
  switch(value.toLowerCase()) {
      case "amazon" : 
        result = require("@/images/amazon.png")
        break;
      case "bigcommerce" : 
        result = require("@/images/bigcommerce.png")
        break;
      case "ebay" : 
        result = require("@/images/ebay.png")
        break;
      case "shopify" : 
        result = require("@/images/shopify.png")
        break;
      case "wix" : 
        result = require("@/images/wix.png")
        break;
      case "woocommerce" : 
        result = require("@/images/woocommerce.png")
        break;
      default : 
        result = require("@/images/blank.png")
  }
  return result;
}
function getTrackingUrl(value, TN) {
  if(value == null) { value = ""; }
  var result ="";
  switch(value.toLowerCase()) {
      case "ups" : 
        result = "https://www.ups.com/track?requester=ST&loc=en_US/trackdeails&track=yes&trackNums="+TN
        break;
      case "px" : 
        result = ""
        break;
      case "ems" : 
        result = "https://service.epost.go.kr/trace.RetrieveEmsRigiTraceList.comm?POST_CODE="+TN
        break;
      case "gem" : 
        result = ""
        break;
      case "fedex_ficp" : 
        result = "https://www.fedex.com/fedextrack/no-results-found?trknbr="+TN
        break;
      case "fedex_ip" : 
        result = "https://www.fedex.com/fedextrack/no-results-found?trknbr="+TN
        break;
      case "fedex_ip_c" : 
        result = "https://www.fedex.com/fedextrack/no-results-found?trknbr="+TN
        break;
      case "gts":
        result = "https://tracking.frontierforce.com/tracking.aspx?stid=GTS&cn="+TN
        break
      case "ups_gnd_fns":
        result = "https://www.ups.com/track?requester=ST&loc=en_US/trackdetails&track=yes&trackNums="+TN
        break
      case "dhl_gnd_trident":
        result = "https://www.dhl.com/us-en/home/tracking/tracking-ecommerce.html?submit=1&tracking-id="+TN
        break
      case "ups_all_pass_fns":
        result = "https://www.ups.com/track?requester=ST&loc=en_US/trackdetails&track=yes&trackNums="+TN
        break
      case "dhl_all_pass_trident":
        result = "https://www.dhl.com/us-en/home/tracking/tracking-ecommerce.html?submit=1&tracking-id="+TN
        break
      case "ics":
        result = ""
        break
      case "mc":
        result = "https://mc.solwith.com/trackinggem?bl="+TN
        break
      case "mc_japan":
        result = "https://k2k.sagawa-exp.co.jp/cgi-bin/mall.mmcgi?oku01="+TN
        break
      case "wise":
        result = ""
        break
      default :  
  }
  return result;
}

const currencyItems = ["AED","AFN","ALL","AMD","ANG","AOA","ARS","AUD","AWG","AZN","BAM","BBD","BDT","BGN","BHD","BIF","BMD","BND","BOB","BOV","BRL","BSD","BTN","BWP","BYN","BYR","BZD","CAD","CDF","CHE","CHF","CHW","CLF","CLP","CNY","COP","COU","CRC","CUC","CUP","CVE","CZK","DJF","DKK","DOP","DZD","EGP","ERN","ETB","EUR","FJD","FKP","GBP","GEL","GHS","GIP","GMD","GNF","GTQ","GYD","HKD","HNL","HRK","HTG","HUF","IDR","ILS","INR","IQD","IRR","ISK","JMD","JOD","JPY","KES","KGS","KHR","KMF","KPW","KRW","KWD","KYD","KZT","LAK","LBP","LKR","LRD","LSL","LYD","MAD","MDL","MGA","MKD","MMK","MNT","MOP","MRO","MUR","MVR","MWK","MXN","MXV","MYR","MZN","NAD","NGN","NIO","NOK","NPR","NZD","OMR","PAB","PEN","PGK","PHP","PKR","PLN","PYG","QAR","RON","RSD","RUB","RWF","SAR","SBD","SCR","SDG","SEK","SGD","SHP","SLL","SOS","SRD","SSP","STD","SVC","SYP","SZL","THB","TJS","TMT","TND","TOP","TRY","TTD","TWD","TZS","UAH","UGX","USD","USN","UYI","UYU","UZS","VEF","VND","VUV","WST","XAF","XAG","XAU","XBA","XBB","XBC","XBD","XCD","XDR","XOF","XPD","XPF","XPT","XSU","XTS","XUA","YER","ZAR","ZMW","ZWL"]
const shippingTermItems = ["DDP", "DAP"]
const serviceTypeItems = [{ name: 'POD', value: 'POD'}, { name: 'D2D', value: 'D2D'}, { name: 'N/A', value: null}]
const packageTypeItems = [{ name: 'CARTON', value: 'CARTON'}, { name: 'PALLET', value: 'PALLET'}, { name: 'POUCH', value: 'POUCH'}, { name: 'N/A', value: null}]
const contentsTypeItems = [ { name: 'GIFT', value: 'GIFT'},  { name: 'SAMPLE', value: 'SAMPLE'}, { name: 'DOCUMENT', value: 'DOCUMENT'}, { name: 'MERCHANDISE', value: 'MERCHANDISE'}]
const stateCAItems = [{ name: 'Alberta', value: 'AB'},{ name: 'British Columbia', value: 'BC'},{ name: 'Manitoba', value: 'MB'},{ name: 'New Brunswick', value: 'NB'},{ name: 'Newfoundland', value: 'NL'},{ name: 'Northwest Territories', value: 'NT'},{ name: 'Nova Scotia', value: 'NS'},{ name: 'Nunavut', value: 'NU'},{ name: 'Ontario', value: 'ON'},{ name: 'Prince Edward Island', value: 'PE'},{ name: 'Quebec', value: 'QC'},{ name: 'Saskatchewan', value: 'SK'},{ name: 'Yukon', value: 'YT'}];
const stateINItems = [{ name: 'Andaman & Nicobar (U.T)', value: 'AN'},{ name: 'Andhra Pradesh', value: 'AP'},{ name: 'Arunachal Pradesh', value: 'AR'},{ name: 'Assam', value: 'AS'},{ name: 'Bihar', value: 'BR'},{ name: 'Chattisgarh', value: 'CG'},{ name: 'Chandigarh (U.T.)', value: 'CH'},{ name: 'Daman & Diu (U.T.)', value: 'DD'},{ name: 'Delhi (U.T.)', value: 'DL'},{ name: 'Dadra and Nagar Haveli (U.T.)', value: 'DN'},{ name: 'Goa', value: 'GA'},{ name: 'Gujarat', value: 'GJ'},{ name: 'Haryana', value: 'HR'},{ name: 'Himachal Pradesh', value: 'HP'},{ name: 'Jammu & Kashmir', value: 'JK'},{ name: 'Jharkhand', value: 'JH'},{ name: 'Karnataka', value: 'KA'},{ name: 'Kerala', value: 'KL'},{ name: 'Lakshadweep (U.T)', value: 'LD'},{ name: 'Madhya Pradesh', value: 'MP'},{ name: 'Maharashtra', value: 'MH'},{ name: 'Manipur', value: 'MN'},{ name: 'Meghalaya', value: 'ML'},{ name: 'Mizoram', value: 'MZ'},{ name: 'Nagaland', value: 'NL'},{ name: 'Orissa', value: 'OR'},{ name: 'Punjab', value: 'PB'},{ name: 'Puducherry (U.T.)', value: 'PY'},{ name: 'Rajasthan', value: 'RJ'},{ name: 'Sikkim', value: 'SK'},{ name: 'Tamil Nadu', value: 'TN'},{ name: 'Tripura', value: 'TR'},{ name: 'Uttaranchal', value: 'UA'},{ name: 'Uttar Pradesh', value: 'UP'},{ name: 'West Bengal', value: 'WB'}];
const stateMXItems = [{ name: 'Aguascalientes', value: 'AG'},{ name: 'Baja California', value: 'BC'},{ name: 'Baja California Sur', value: 'BS'},{ name: 'Campeche', value: 'CM'},{ name: 'Chiapas', value: 'CS'},{ name: 'Chihuahua', value: 'CH'},{ name: 'Ciudad de México', value: 'DF'},{ name: 'Coahuila', value: 'CO'},{ name: 'Colima', value: 'CL'},{ name: 'Durango', value: 'DG'},{ name: 'Estado de México', value: 'EM'},{ name: 'Guanajuato', value: 'GT'},{ name: 'Guerrero', value: 'GR'},{ name: 'Hidalgo', value: 'HG'},{ name: 'Jalisco', value: 'JA'},{ name: 'Michoacán', value: 'MI'},{ name: 'Morelos', value: 'MO'},{ name: 'Nayarit', value: 'NA'},{ name: 'Nuevo León', value: 'NL'},{ name: 'Oaxaca', value: 'OA'},{ name: 'Puebla', value: 'PU'},{ name: 'Querétaro', value: 'QE'},{ name: 'Quintana Roo', value: 'QR'},{ name: 'San Luis Potosí', value: 'SL'},{ name: 'Sinaloa', value: 'SI'},{ name: 'Sonora', value: 'SO'},{ name: 'Tabasco', value: 'TB'},{ name: 'Tamaulipas', value: 'TM'},{ name: 'Tlaxcala', value: 'TL'},{ name: 'Veracruz', value: 'VE'},{ name: 'Yucatán', value: 'YU'},{ name: 'Zacatecas', value: 'ZA'}];
const stateUSItems = [{ name: 'Alabama', value: 'AL'},{ name: 'Alaska', value: 'AK'},{ name: 'Arizona', value: 'AZ'},{ name: 'Arkansas', value: 'AR'},{ name: 'California', value: 'CA'},{ name: 'Colorado', value: 'CO'},{ name: 'Connecticut', value: 'CT'},{ name: 'Delaware', value: 'DE'},{ name: 'District of Columbia', value: 'DC'},{ name: 'Florida', value: 'FL'},{ name: 'Georgia', value: 'GA'},{ name: 'Hawaii', value: 'HI'},{ name: 'Idaho', value: 'ID'},{ name: 'Illinois', value: 'IL'},{ name: 'Indiana', value: 'IN'},{ name: 'Iowa', value: 'IA'},{ name: 'Kansas', value: 'KS'},{ name: 'Kentucky', value: 'KY'},{ name: 'Louisiana', value: 'LA'},{ name: 'Maine', value: 'ME'},{ name: 'Maryland', value: 'MD'},{ name: 'Massachusetts', value: 'MA'},{ name: 'Michigan', value: 'MI'},{ name: 'Minnesota', value: 'MN'},{ name: 'Mississippi', value: 'MS'},{ name: 'Missouri', value: 'MO'},{ name: 'Montana', value: 'MT'},{ name: 'Nebraska', value: 'NE'},{ name: 'Nevada', value: 'NV'},{ name: 'New Hampshire', value: 'NH'},{ name: 'New Jersey', value: 'NJ'},{ name: 'New Mexico', value: 'NM'},{ name: 'New York', value: 'NY'},{ name: 'North Carolina', value: 'NC'},{ name: 'North Dakota', value: 'ND'},{ name: 'Ohio', value: 'OH'},{ name: 'Oklahoma', value: 'OK'},{ name: 'Oregon', value: 'OR'},{ name: 'Pennsylvania', value: 'PA'},{ name: 'Rhode Island', value: 'RI'},{ name: 'South Carolina', value: 'SC'},{ name: 'South Dakota', value: 'SD'},{ name: 'Tennessee', value: 'TN'},{ name: 'Texas', value: 'TX'},{ name: 'Utah', value: 'UT'},{ name: 'Vermont', value: 'VT'},{ name: 'Virginia', value: 'VA'},{ name: 'Washington State', value: 'WA'},{ name: 'West Virginia', value: 'WV'},{ name: 'Wisconsin', value: 'WI'},{ name: 'Wyoming', value: 'WY'},{ name: 'Puerto Rico', value: 'PR'}];
const stateAEItems = [{ name: 'Abu Dhabi', value: 'AB'},{ name: 'Ajman', value: 'AJ'},{ name: 'Dubai', value: 'DU'},{ name: 'Fujairah', value: 'FU'},{ name: 'Ras al-Khaimah', value: 'RA'},{ name: 'Sharjah', value: 'SH'},{ name: 'Umm al-Quwain', value: 'UM'}];

const messages = {
  email : 'Please check your Email!',
  password : 'Please check your Password!',
  firstName : 'Please check your First name!',
  lastName : 'Please check your Last name!',
  mobileNo : 'Please check your Mobile number!',
  phoneNo : 'Please check your Phone number!',
  addressCountry : 'Please check your Country!',
  zipCode : 'Please check your Postcode!',
  address1 : 'Please check your address1!',
  addressCity : 'Please check your City!',
  addressState : 'Please check your State!',
  companyName : 'Please check your Company name!',
  companyTelNo : 'Please check your Company tel no!',
  companyRegisterNo : 'Please check your Company register no!',
  wait : 'Coming Soon!',
  login : 'Login Failed!',
  expiredAccount : 'Your account has been expired!',
  approval : 'Please wait for approval from GEM!',
  agree : "Please agree GEM's T&Cs to register!",
  welcome : 'Welcome to Gem service. Gem expert will contact you within 24 hours!',
  passwordModifyComplate : 'Your password has been updated!',
  
  orderNo : 'Please enter Order Number!',
  orderNo26 : 'Max of 26 characters for Order Number!',
  storePlatform : 'Please enter Marketplace/Platform!',
  storePlatform30 : 'Max of 30 characters for Marketplace/Platform!',
  storeName : 'Please enter Store Name!',
  storeName30 : 'Max of 30 characters for Store Name!',
  orderedAt : 'Please enter Order Date!',
  orderedAtYYYYMMDD : 'Please check the Format of Order Date (YYYYMMDD)!',
  shippingTerm : 'Please enter Shipping Term!',
  shippingTermFilter : 'Enter One of Shipping Terms - DDP, DAP!',
  senderName : 'Please check Sender name!',
  senderName23 : 'Max of 23 characters for Sender Name!',
  senderEmail : 'Please enter Sender Email!',
  senderEmail50 : 'Max of 50 characters for Sender Email!',
  senderEmailFilter : 'Please check the Format of Email Address!',
  senderPhoneNo : 'Please enter Sender Phone Number!',
  senderPhoneNo30 : 'Max of 30 characters for Sender Phone Number!',
  senderMobileNo : 'Please enter Sender Mobile Number!',
  senderMobileNo30 : 'Max of 30 characters for Sender Mobile Number!',
  senderAddress1 : 'Please enter Sender Street1!',
  senderAddress68 : 'Max of 68 characters for Sender Street1 + Sender Street2!',
  senderAddressCity : 'Please enter Sender City!',
  senderAddressCity30 : 'Max of 30 characters for Sender City!',
  senderAddressState : 'Please enter Sender State!',
  senderCity45 : 'Max of 45 characters for Sender City + Sender State/Province!',
  senderZipCode : 'Please enter Sender Post Code!',
  senderZipCode20 : 'Max of 20 characters for Sender Post Code!',
  senderAddressCountryCode : 'Please enter Sender Country Code!',
  senderAddressCountryCodeFilter : 'Invalid Sender Country Code!',
  recipientName : 'Please enter Recipient Name!',
  recipientName23 : 'Max of 23 characters for Recipient Name!',
  recipientEmail : 'Please enter Recipient Email!',
  recipientEmail50 : 'Max of 50 characters for Recipient Email!',
  recipientEmailFilter : 'Please check the Format of Email Address!',
  recipientPhoneNo : 'Please enter Recipient Phone Number!',
  recipientPhoneNo30 : 'Max of 30 characters for Recipient Phone Number!',
  recipientMobileNo : 'Please enter Recipient Mobile Number!',
  recipientMobileNo30	: 'Max of 30 characters for Recipient Mobile Number!',
  recipientAddress1 : 'Please enter Recipient Street1!',
  recipientAddress68 : 'Max of 68 characters for Recipient Street1 and Recipient Street2!',
  recipientAddressCity : 'Please enter Recipient City!',
  recipientAddressCity30 : 'Max of 30 characters for Recipient City!',
  recipientAddressState : 'Please enter Recipient State!',
  recipientCity45 : 'Max of 45 characters for Recipient City and Recipient State/Province!',
  recipientZipCode : 'Please enter Recipient Post code!',
  recipientZipCode20 : 'Max of 20 characters for Recipient Post Code!',  
  recipientAddressCountryCode : 'Please enter Recipient Country Code!',
  recipientAddressCountryCodeFilter : 'Invalid Recipient Country Code!',
  recipientAddressJapanAddress1 : 'Plese enter Recipient Street1 Only Japanese',
  recipientAddressJapanAddress2 : 'Plese enter Recipient Street2 Only Japanese',
  recipientAddressJapanAddressCity : 'Plese enter Recipient City Only Japanese',
  recipientLocalNameJapan : 'Plese enter Recipient Local Name Only Japanese',
  shipmentActualWeight : 'Please enter Shipment Actual Weight!',
  shipmentActualWeightFloat : 'Enter Numbers and Dot only for Shipment Actual Weight!',
  shipmentVolumeWeight : 'Please enter Shipment Volume Weight!',
  shipmentVolumeWeightFloat : 'Enter Numbers and Dot only for Shipment Volume Weight!',
  weightUnit : 'Max of 10 characters for Weight Unit!',
  width : 'Enter Numbers and Dot only for Width!',
  length : 'Enter Numbers and Dot only for Length!',
  height : 'Enter Numbers and Dot only for Height!',
  packageType : 'Enter One of Package Types - Carton, Pouch, Pallet!',
  parcelCount : 'Please enter Parcel Count!',
  parcelCountNumber : 'Enter Numbers only for Parcel Count!',
  parcelIndex : 'Please enter Parcel Index!',
  parcelIndexNumber : 'Enter Numbers only for Parcel Index!',
  parcelCountIndex : "The number of Parcel Index can't exceed Parcel Count!",
  serviceType : 'Please enter Service Type!',
  serviceTypeFilter: 'Enter One of Service Types - D2D, POD!',
  itemContentsType : 'Please enter Contents Type!',
  itemContentsTypeFilter : 'Enter One of Contents Type - Document, Gift, Sample, Merchandise!',
  itemName : 'Please enter Item Name!',
  itemQuantity : 'Please enter Item Quantity!',
  itemQuantityNumber : 'Enter Numbers only for Item Quantity!',
  itemUnitValue : 'Please enter Item Unit Value!',
  itemUnitValueFloat : 'Enter Numbers and Dot only for Item Value!',
  itemValueCurrency : 'Please enter Item value currency!',
  itemValueCurrencyJPY : 'Please change Item value currency JPY!',
  itemValueCurrencyFilter : 'Invalid Item Value Currency!',
  itemWeight : 'Please enter Item Weight!',
  itemWeightFloat : 'Enter Numbers and Dot only for Item Weight!',
  itemHSCode : 'Please enter Item HS Code!',
  itemHSCodeFloat : 'Enter Numbers and Dot onlyfor Item HS Code!',
  itemOriginCountryCode : 'Please enter Item Origin Country Code!',
  itemOriginCountryCodeFilter : 'Invalid Item Origin Country Code!',
  itemSalesURL : 'Please enter Item Sales URL!',
  itemSalesURL200 : 'Max of 200 characters for Item Sales URL!',
  itemBarcode : 'Please enter Item Barcode!',
  itemBarcode20 : 'Max of 20 characters for Item Barcode!',
  itemBarcode30 : 'Max of 30 characters for Item Barcode Number!',
  itemBarcodeMixEngNum : 'Please enter a mixture of English and numbers!',
  itemSKU255 : 'Max of 255 characters for Item SKU!',
  
  
  orderItemsLength : 'Please check Item quantity!',
  itemNameLength : 'Max length of item name is 100 Character!',
  
  itemParcelIndex : 'Please check Item parcel index!',
  
  orderNewComplate : 'New Order has been updated!',
  
  orderSelect : 'Please select the order!',
  
  orderIsDeleted : 'The order has been deleted!',
  orderDelete : 'Are you sure to delete the order?',
  orderDeleteComplate : 'Deleted!',
  
  orderModifyComplate : 'Completed!',
  
  orderShipment : 'Would you proceed orders to Shipment Management?',
  orderShipmentComplate : 'Completed!',
  
  excelUpladFile : 'Please check Upload file!',
  excelUploadFileComplate : 'Completed!',
  
  shipmentSelect : 'Please select the shipment!',
  
  shippngMethodComplate : 'Completed!',
  shipmetDelete : 'Are you sure to move the order back to Order Management?',
  shipmentDeleteComplate : 'Completed!',
  
  shippingMethodSelect : 'Please select a Shipping Method!',
  
  AWBPrinted : "It can''t be done as AWB has been printed out!",
  
  barcodePrintCheck : "Plase Check a Barcode Print!",
  awbPrintCheck : "Plase Check a AWB Print!",
  barcode : 'Shipments including Shipping method is required or AWB has been printed!',

  shippingFrom : 'Please check Shipping From!',
  
  cardNumber : "Please enter Card Number!",
  cardNickname : 'Please enter Card Nickname!',  
  expireDateMM : 'Please enter Card expireDateMM!',  
  expireDateYY : 'Please enter Card expireDateYY!',  
  birthDay : 'Please enter BirthDay!',  
  holderName : 'Please enter Name On Card!',  
  cardDelete : 'Are you sure to delete the card?',

  paymentSelect : 'Please select the payment!',
  weightSelect : 'Please select the Weight!',

  shippingMethodCheck : "Please Check a Shipping Method!",
  AWBPrintReCheck : "You can print the AWB of 'UPS / EMS / PX / GEM'!",

  paymentSuccess : "Payment success",
  paymentFail : "Payment fail",
  
  paymentFailComplate : "Payment fail complete!",

  paymentStatusCheck : "Please check your payment status.",
  smsSelect : "Please select a recipient.",
  pageError : "This is not a normal approach.",
  selectOnlyOne : "Please select only one.",
  smsParamCheck : "Check message recipients.",
  smsSendOk : "A payment message has been sent.",

  orderItemCheck: "Check order item.",

  otherChargeTypeCheck: "Please check the type",
  otherChargeAmountCheck: "Please check the amount",

  openTrackingNumberCheck: "Please check tracking number",

  signatureCheck : "The signature system is only available when GEM or GEM PASS of less than 3 kg is selected.",
}

export default {
    makeAPIUrl,
    trackingStepBackgroundColor,
    getYYYMMDD,
    getYYYMMDDHHMMSS,
    getCountry,
    getRateImgUrl,
    getCarrierName,
    getPlatformImgUrl,
    getTrackingUrl,
    currencyItems,
    shippingTermItems,
    serviceTypeItems,
    packageTypeItems,
    contentsTypeItems,
    stateCAItems,
    stateINItems,
    stateMXItems,
    stateUSItems,
    stateAEItems,
    messages,
    getCarrierServiceImage,
}
