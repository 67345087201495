function nowYYYYmmdd() {
    let date = new Date()

    let year = date.getFullYear()
    let month = date.getMonth() + 1
    let day = date.getDate()

    month = month >= 10 ? month : "0" + month
    day = day >= 10 ? day : "0" + day

    return year + "" + month + "" + day
}

function nowYYYYmmddhhmmss() {
    var date = new Date();
    var year = date.getFullYear().toString();

    var month = date.getMonth() + 1;
    month = month < 10 ? '0' + month.toString() : month.toString();

    var day = date.getDate();
    day = day < 10 ? '0' + day.toString() : day.toString();

    var hour = date.getHours();
    hour = hour < 10 ? '0' + hour.toString() : hour.toString();

    var minites = date.getMinutes();
    minites = minites < 10 ? '0' + minites.toString() : minites.toString();

    var seconds = date.getSeconds();
    seconds = seconds < 10 ? '0' + seconds.toString() : seconds.toString();

    return year + "" + month + "" + day + "" + hour + "" + minites + "" + seconds;
}

function toYYYYmmdd() {
    let date = new Date()

    let year = date.getFullYear()
    let month = date.getMonth() + 1
    let day = date.getDate()

    month = month >= 10 ? month : "0" + month
    day = day >= 10 ? day : "0" + day

    return year + "-" + month + "-" + day
}

function fromYYYYmmdd() {
    let now = new Date();	// 현재 날짜 및 시간
    let date = new Date(now.setDate(now.getDate() - 14));

    let year = date.getFullYear()
    let month = date.getMonth() + 1
    let day = date.getDate()

    month = month >= 10 ? month : "0" + month
    day = day >= 10 ? day : "0" + day

    return year + "-" + month + "-" + day
}

export default {
    nowYYYYmmdd,
    nowYYYYmmddhhmmss,
    toYYYYmmdd,
    fromYYYYmmdd,
}