<template>
  <v-app>
  <div id="app">
    <div style="display:none;margin-bottom:30px" id="top_header">
      <div>
        <v-app-bar id="bar_header" color="#E2E1F1">
          <v-toolbar-title>
            <v-img alt="" class="shrink mt-1 hidden-sm-and-down" contain src="./images/top_logo.png" width="200px"/>
          </v-toolbar-title>
          <b><h2>&nbsp;&nbsp;&nbsp;<font style="color:#4D4D4D"><span id="topNaviText"></span></font></h2></b>
          <v-spacer></v-spacer>
          <span>Welcome Entrepreneur<b>&nbsp;<span id="companyName"></span>!</b>&nbsp;&nbsp;&nbsp;&nbsp;</span>&nbsp;&nbsp;
            <!--<v-img @click="setHome()" alt="" class="shrink mt-1 hidden-sm-and-down ma-1 pd-1" contain src="./images/header4.png" width="40px" style="cursor: pointer;"/>-->
            <v-img @click="setAlarm" alt="" class="shrink mt-1 hidden-sm-and-down ma-1 pd-1" contain src="./images/header1.png" width="40px"/>
            <v-img alt="" class="shrink mt-1 hidden-sm-and-down ma-1 pd-1" contain src="./images/header2.png" width="40px"/>
            <v-img @click="setLogout" alt="" class="shrink mt-1 hidden-sm-and-down ma-1 pd-1" contain src="./images/header3.png" width="40px" style="cursor: pointer;"/>
        </v-app-bar>
      </div>      
    </div>
    <router-view/>
    <notifications group="notifyApp" position="bottom center" align="right"/>
  </div>

  <div id="tabBarMaster" style="position: fixed; bottom: 0; width: 100%; display:none;">
    <div id="footer" style="font-size:13px;margin-bottom:5px">
      GEM Platform. Business Reg No.: 804-86-02326. Mail-order business Reg No.: 2022-Seoul Gangseo-2781. A403, PineSquare, 22 Magokjungang 4-ro, Gangseo-gu, Seoul. +82-2-2664-4488. gem@gemplatforms.com
    </div>
    <v-card>
      <v-tabs v-model="tab" id="tabBarMasterList" background-color="transparent" center-active dark left style="height: 30px;">
        <v-tab v-for="item in tabItems" :key="item.tab" v-bind:style="item.tabStyle" @click="changeTab(item.tab)">
          {{ item.tab }}<v-img v-show="item.deleteIcon" alt="" class="shrink mt-1 hidden-sm-and-down" contain src="@/images/x.png" width="15px" @click="tabDelete(item)" style="margin-left:5px;margin-bottom:3px;cursor:pointer" />
        </v-tab>
      </v-tabs>
    </v-card>
  </div>

  </v-app>
</template>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
.v-application--wrap {
  min-height: 0vh !important;
}
</style>

<script>
// eslint-disable-next-line no-unused-vars
import axios from 'axios'
import $ from 'jquery'
import VueCookies from 'vue-cookies'

export default {
  data: () => ({
      tab: null,
      tabItems: [{tab:"HOME", tabStyle:"background-color: #7A7982;height: 30px;", deleteIcon:false}],
  }),
  methods: {
    setAlarm() {
      this.$alert("Alarm Action.", '', 'info').then(() => { this.$refs.password.focus() })
    },
    setLogout() {
      this.$session.set('loginId', '');
      this.$session.set('authorization', '');
      VueCookies.set("companyName", '');
      this.getToken();
      $("#tabBarMaster").hide();
      window.open(process.env.VUE_APP_HOMEPAGE_URL, "_self");
    },
    setHome() {
      this.$router.push('/home');
    },
    getToken() {
      let formData = new FormData();
      formData.append("grant_type", "password");
      formData.append("username", process.env.VUE_APP_AUTH_USERNAME);
      formData.append("password", process.env.VUE_APP_AUTH_PASSWORD);

      // eslint-disable-next-line no-unused-vars
      axios
        .post(process.env.VUE_APP_AUTH_API_URL, formData, {
          headers: {
            Authorization: process.env.VUE_APP_AUTH_TOKEN,
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json; charset = utf-8",
          },
        })
        // eslint-disable-next-line no-unused-vars
        .then((response) => {
          this.authorization = response.data.token_type + " " + response.data.access_token;
          this.$session.set("authorization", this.authorization);
        })
        .catch((err) => {
          if (
            err.response.data.error_description === "Bad credentials" ||
            err.response.data.error === "invalid_grant"
          ) {
            this.$alert("사용자 아이디 또는 비밀번호를 확인하시기 바랍니다.", "", "info").then(() => {});
          } else if (err.response.data.error === "unauthorized") {
            this.$alert(err.response.data.error_description, "", "info").then(() => {});
          } else if (err.response.data.error_description === "Bad credentials (AX)") {
            this.$alert("사용자 계정의 유효 기간이 만료 되었습니다.", "", "info").then(() => {});
          } else if (err.response.data.error_description === "Bad credentials (AL)") {
            this.$alert("사용자 계정이 잠겨 있습니다.", "", "info").then(() => {});
          } else if (err.response.data.error_description === "Bad credentials (CX)") {
            this.$alert("자격 증명 유효 기간이 만료되었습니다.", "", "info").then(() => {});
          } else if (err.response.data.error_description === "Bad credentials (NA)") {
            this.$alert("유효하지 않은 사용자입니다.", "", "info").then(() => {});
          } else if (err.response.data.error_description === "Bad credentials (UC)") {
            this.$alert("접근이 허용되지 않은 사용자입니다.", "", "info").then(() => {});
          } else if (err.response.data.error_description !== undefined) {
            this.$alert(err.response.data.error_description, "", "info").then(() => {});
          }
        });
    },
    changeTab(tabId) {
      var changeCheck = true;
      for(var tabCount=0; tabCount < this.tabItems.length; tabCount++) {
        if(tabId == this.tabItems[tabCount].tab) {
          if(this.tab == tabCount)
          {
            changeCheck = false;
          }
        }
      }
      if(changeCheck) {
        if(tabId == "REPORT") {
          this.$router.push("/report-weight-list");
        } else {
          this.$router.push("/" + tabId.toLowerCase());
        }
      }
    },
    tabDelete(item){
      this.tabItems.splice(this.tabItems.indexOf(item), 1);
      this.tab = 0;
      this.$router.push("/home");
    }
  },
  mounted() {  
    $("#companyName").text(VueCookies.get('companyName'));
    if(window.location.href.indexOf('home') != -1 || window.location.href.indexOf('order') != -1 || window.location.href.indexOf('shipping') != -1 || window.location.href.indexOf('seller-master') != -1
    || window.location.href.indexOf('report-weight-list') != -1) {
      $("#tabBarMaster").show();    
    } else {
      $("#tabBarMaster").hide();
    }

    if(window.location.href.indexOf('payment-card-success') != -1 || window.location.href.indexOf('payment-card-fail') != -1) {
      $("#tabBarMaster").hide();
    }

    this.getToken();

    setTimeout(function() {
      $("iframe").hide()
    }, 500);
    

    //1시간마다 토큰 갱신
    var vm = this;
    setInterval(function() {
      vm.getToken();
    } , 3600000);

    this.$session.set("sessionTabItems", this.tabItems);
    this.tabItems = this.$session.get('sessionTabItems');    
  },
  watch:{
    $route (to){
      this.$session.set("sessionTabItems", this.tabItems);

      var sessionTabItems = this.$session.get('sessionTabItems');
      var item = Object();
      var tabCheck = true;

      for(var tabCount=0; tabCount < sessionTabItems.length; tabCount++) {
        var toName = to.name.toUpperCase()
        if(toName == "SELLER-MASTER-PAYMENT" || toName == "SELLER-MASTER-SHIPPING" || toName == "SELLER-MASTER-PACKAGING") { toName = "SELLER-MASTER"; }
        if(toName == "REPORT-WEIGHT-LIST") { toName = "REPORT"; }
        if(sessionTabItems[tabCount].tab == toName) {
          tabCheck = false;
        }
      }

      if(tabCheck == true) {
        item.tab = to.name.toUpperCase();
        if(item.tab == "REPORT-WEIGHT-LIST") { item.tab = "REPORT" }
        if(item.tab == "SELLER-MASTER-PAYMENT" || item.tab == "SELLER-MASTER-SHIPPING" || item.tab == "SELLER-MASTER-PACKAGING") { item.tab = "SELLER-MASTER"; }
        
        if(item.tab == "ORDER") { item.tabStyle = "background-color: #E99EBB;height: 30px;"; }
        if(item.tab == "SHIPPING") { item.tabStyle = "background-color: #BAE8B6;height: 30px;"; }
        if(item.tab == "GLOVIS-SHIPPING") { item.tabStyle = "background-color: #BAE8B6;height: 30px;"; }
        if(item.tab == "SELLER-MASTER") { item.tabStyle = "background-color: #FFC971;height: 30px;"; }
        if(item.tab == "REPORT") { item.tabStyle = "background-color: #1BB5F2;height: 30px;"; }
        item.deleteIcon = true;
        sessionTabItems.push(item);
      }
      
      this.$session.set("sessionTabItems", sessionTabItems);

      for(var j=0; j < sessionTabItems.length; j++) {
        if(sessionTabItems[j].tab == item.tab) {
          this.tab = j;
        }
      }
      this.tabItems = this.$session.get('sessionTabItems');
    }    
  },

  updated() {
    //alert(this.tabItems[this.tabCurrent])
  },
}
</script>
